import axios from "axios";
import { getCookie } from "cookies-next";

import USER_CONSTANTS from "@arcave/constants/userStorageConstants";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  if (config.url?.startsWith("/api")) {
    config.url = config.url.replace("/api", "");
    const token = getCookie(USER_CONSTANTS.STORAGE_SAVE_KEY.USER_TOKEN);
    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  }

  if (config.url?.startsWith("/client")) {
    config.baseURL = `/`;
    config.url = config.url.replace("/client", "");

    return config;
  }

  if (config.url?.startsWith("/client-api")) {
    config.baseURL = `/`;
    config.url = config.url.replace("/client-api", "");

    return config;
  }

  return config;
});

export default axiosInstance;
