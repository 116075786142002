const USER_CONSTANTS = {
  /** storage 에 잠시 저장하기 위해 사용하는 key 입니다 */
  STORAGE_SAVE_KEY: {
    USER_EMAIL: "USER_EMAIL",
    USER_ID: "USER_ID",
    USER_TOKEN: "USER_TOKEN",
    USER_INFO: "USER_INFO",
  },
};
export default USER_CONSTANTS;
